import { DefaultLayout } from "./layout/DefaultLayout";
import { Home } from "./Home";
import { Login } from "./auth/Login";
import { PasswordRecovery } from "./auth/PasswordRecovery";
import { PasswordReset } from "./auth/PasswordReset";
import { Error404 } from "./layout/Error404";

// import { ProductsList } from 'views/product/List';
// import { ProductsCreate } from 'views/product/Create';
// import { ProductsEdit } from 'views/product/Edit';
import { CustomersList } from 'views/customer/List';
// import { OrdersList } from 'views/order/List';
// import { OrdersCreate } from 'views/order/Create';
// import { OrdersEdit } from 'views/order/Edit';

// import { SkusList } from 'views/sku/List';

export const Views = {
    DefaultLayout,
    Home,

    Login,
    PasswordRecovery,
    PasswordReset,

    // OrdersList, OrdersCreate, OrdersEdit,
    // ProductsList, ProductsCreate, ProductsEdit,
    CustomersList,
    // SkusList,

    Error404,
};
